import Vue from 'vue';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import {
  required,
  email,
  numeric,
  min_value,
  max_value,
  double,
  integer,
} from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Register the validation rules (you can add more if needed)
extend('required', {
  ...required,
  message: `{_field_} is required.`,
});
extend('numeric', {
  ...numeric,
  message: `{_field_} must be numeric.`,
});
extend('email', {
  ...email,
  message: 'Please enter a valid email address.',
});
extend('integer', {
  ...integer,
  message: `{_field_} must be integer.`,
});
extend('double', {
  ...double,
  message: `{_field_} must be numeric.`,
});
extend('min_value', {
  ...min_value,
  message: '{_field_} must be greater than {min}.',
});
extend('max_value', {
  ...max_value,
  message: '{_field_} must be less than {max}.',
});
extend('regex', {
  ...max_value,
  message: '{_field_} must be valid regex.',
});

extend('regexValidator', (value) => {
  try {
    new RegExp(value);
    return true;
  } catch (error) {
    return 'Invalid regular expression.';
  }
});
