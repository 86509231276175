import { computed, ref } from "vue"

type ModalConfig = {
  maxWidth?: string
  mode?: "centered" | "top"
}

const _component = ref()
const _props = ref()
const _events = ref()
const _modalConfig = ref()

export function useGlobalModal() {
  function open(component: any, opts?: {
    props?: Record<string, any>,
    eventHandlers?: Record<string, any>,
    modalConfig?: ModalConfig
  }) {
    _component.value = component
    _props.value = opts?.props
    _events.value = opts?.eventHandlers
    _modalConfig.value = opts?.modalConfig
  }

  function close() {
    _component.value = undefined
    _props.value = undefined
    _events.value = undefined
    _modalConfig.value = undefined
  }

  return {
    isActive: computed(() => !!_component.value),
    component: computed(() => _component.value),
    props: computed(() => _props.value),
    events: computed(() => _events.value),
    modalConfig: computed(() => _modalConfig.value),
    open,
    close
  }
}