import { NavigationGuardNext, Route } from "vue-router"
import supabase from "@/store/supabase-client"
import { isAuthenticationRoute } from "@/router/utils"
import { RouterBuilder } from "@/router/router-builder"

export default async function prohibitIfAuthenticatedGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  const isAuthenticated = await supabase.isAuthenticated()

  if(isAuthenticated && isAuthenticationRoute(to.name as string)) {
    return next(RouterBuilder.getAppsRoute())
  }

  next()
}