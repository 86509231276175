/**
 * Actions the user can perform upon error.
 */
export enum ActionType {
  NONE = "none",
  LINK = "link",
  CONTACT = "contact",
  CUSTOM = 'custom'
}

/**
 * No action is required.
 */
export type NoAction = {
  type: ActionType.NONE
}

/**
 * Redirect the user to another link.
 */
export type LinkAction = {
  type: ActionType.LINK
  url: string
  text: string
}

/**
 * Tell the user to contact us.
 */
export type ContactAction = {
  type: ActionType.CONTACT
	text: string
}

export type CustomAction = {
	type: ActionType.CUSTOM
	text: string
}

export type Action = null | LinkAction | ContactAction | CustomAction
