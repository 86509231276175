import { ref } from 'vue'
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';

export const liveClusters = ref<string[]>([])
export const deadClusters = ref<string[]>([])

export function filterDeadClusters(request: PostgrestFilterBuilder<any>, clusterFieldName = 'cluster_id') {
	if(liveClusters.value.length < deadClusters.value.length) {
		return request.filter(clusterFieldName, 'in', `(${liveClusters.value.join(',')})`)
	} else if(deadClusters.value.length > 0) {
		return request.filter(clusterFieldName, 'not.in', `(${deadClusters.value.join(',')})`)
	} else {
		return request
	}
}
