import { definitions } from "@/store/supabase-autogenerated"
import { FindingType } from "@/store/findings/types"

export enum FindingSource {
    PROMETHEUS = "prometheus"
}

export type Issue = definitions["Issues"] // This is a DB type, and should not be used other than typing

// TODO: Rename to Finding once we complete the migration
export type FindingV2 = {
    id: string
    account_id: string
    subject_cluster?: string
    subject_namespace?: string
    subject_name?: string
    subject_type?: string // TODO: Check if we can use Kind enum (resource/types.ts)
    fingerprint?: string
    service_key?: string
    source?: string
    category?: string
    priority?: string // TODO: Can be changed to FindingPriority (only like this be compatible with Finding)
    service_name?: string
    finding_name?: string
    name?: string // TODO: Rename to alertName, because "name" is not very explicit
    nameCorrected: string // TODO: Eventually we should use this as the default name
    title?: string
    description?: string
    start_date: Date
    rawStartDate?: Date // TODO: Only optional because needs to be compatible with Finding
    end_date?: Date
    findingType?: FindingType
    latestRetransmission?: Date // TODO: Only optional because needs to be compatible with Finding
    is_failure: boolean, // TODO: Check if we really need this
    db_creation_date: string, // TODO: Check if we really need this
    db_last_modified_date: string, // TODO: Check if we really need this

    labels?: Record<string, string>
    annotations?: Record<string, string>
}

export const FIRING_ALERT_WINDOW_HOURS = 6
export const RECENT_CRASHES_WINDOW_HOURS = 6