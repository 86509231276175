// NOTE: this file fetches/filters/sorts data via standard JS promises - keep it free of Vue/swrv stuff
import supabase from "@/store/supabase-client"
import { ACTION_RESTART_ROLLOUT, Service } from "@/store/services/types"
import { filterDeadClusters } from "@/store/dead-clusters";
import { ManualActionRequest } from "@/manual-actions/types"
import { getEvidenceFromResponse } from "@/manual-actions/helpers"
import sendManualActionsToRelay from "@/manual-actions/relay-api/actions"
import { returnUIActionData } from "../utils"
import { mapRawService } from "@/store/services/mapper"
import { trackError, ErrorName } from '@/utils/track-error';

export enum ComparisonMode {
  Cluster = 'CLUSTER',
  Namespace = 'NAMESPACE'
}

export interface ComparedService {
  images: Array<{
    name: string;
    isChanged: boolean
  }>,
  labels: Array<{
    name: string;
    value?: string;
    isChanged: boolean
  }>
  volumes: Array<{
    name: string;
    isChanged: boolean
  }>
  containers: Array<any>
  isChanged: boolean
}

type ServicesQueryOptions = {
  cluster: string
  namespace: string
  kind: string
  name: string
  getDeleted: boolean
  filterDead: boolean
  updatedAfter: Date
  limit: number
}
const DEFAULT_SERVICES_QUERY_OPTIONS = {
  getDeleted: false,
  filterDead: true
}
export async function fetchServices(
  accountId: string,
  options?: Partial<ServicesQueryOptions>
): Promise<Service[]> {
  const config = { ...DEFAULT_SERVICES_QUERY_OPTIONS, ...options }

	const requestMock = supabase.client
    .from<Service>('Services')
    .select('*')
	  .eq('account_id', accountId)

	const request = config.filterDead
    ? filterDeadClusters(requestMock, 'cluster')
    : requestMock

  if(config.cluster) {
    request.eq('cluster', config.cluster)
  }

  if(config.namespace) {
    request.eq('namespace', config.namespace)
  }

  if(config.kind) {
    request.eq('type', config.kind)
  }

  if(config.name) {
    request.eq('name', config.name)
  }

  if(!config.getDeleted) {
    request.eq('deleted', false)
  }

  if(options?.updatedAfter) {
    request.gt('update_time', options.updatedAfter.toISOString())
  }

  if(config.limit) {
    request.limit(config.limit)
  }

  const response = await request
  if (!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_SERVICES);
    throw Error(`Error getting apps`)
  }

  const services = response.data.map(mapRawService)
  return Object.freeze(services) as Service[]
}

export async function rolloutRestart(
  accountId: string,
  sessionToken: string,
  args: { cluster_id: string; namespace: string; kind: string, name: string }
): Promise<string> {
  const actionRequest = new ManualActionRequest(
    accountId,
    args.cluster_id,
    ACTION_RESTART_ROLLOUT,
    {
      name: args.name,
      namespace: args.namespace,
      kind: args.kind,
    }
  )

  const responses = await sendManualActionsToRelay(sessionToken, [
    actionRequest,
  ])
  const { data, error } = responses[0]

  if (error) {
    trackError(ErrorName.FAILED_TO_ROLLOUT_RESTART); 
    throw error
  }

  if (!data?.data?.findings?.length) return ''

  const evidence = getEvidenceFromResponse(data)

  return returnUIActionData(evidence)
}
