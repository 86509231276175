import { isClusterSupported, isClusterAlive, useClustersStatuses, MIN_LIGHT_ACTIONS_VERSION } from "@/store/cluster/composition";
import { ref, ComputedRef, computed } from 'vue'
import { CONTACT_US_MSG, CLUSTER_NOT_CONNECTED_MSG, UPGRADE_MSG } from "@/store/cluster/types";
import { Notification, NotificationType } from "@/notifications/types"
import { contactUsAction, upgradeClusterAction } from "@/notifications/actions/action-presets"

export const ACTION_POD_RESTART = "delete_pod";
export const ACTION_POD_LOGS = "logs_enricher";
export const ACTION_PODS_GET = "related_pods";
export const ACTION_PROMETHEUS_METRICS = 'prometheus_all_available_metrics'
export const ACTION_PROMETHEUS_SERIES = 'prometheus_get_series'
export const ACTION_POD_GRAPH = "prometheus_enricher";
export const ACTION_SILENCE_DELETE = "delete_silence";
export const ACTION_SILENCE_CREATE = "add_silence";
export const ACTION_SILENCE_GET = "get_silences";

export const ACTION_RESOURCE_EVENT_ENRICHER = "resource_events_enricher"

export const ACTION_JOB_POD_ENRICHER = "job_pod_enricher"
export const ACTION_JOB_EVENT_ENRICHER = "job_events_enricher"
export const ACTION_GET_YAML = "get_resource_yaml"

export const ACTION_KRR_SCAN = "krr_scan"
export const ACTION_RUN_POPEYE_SCAN = "popeye_scan"

export const ACTION_ASK_HOLMES = "ask_holmes"
export const ACTION_WORKLOAD_HEALTH_HOLMES = "holmes_workload_health"
export const HOLMES_CHAT_ACTION = "holmes_chat"

export const ACTIONS_JOBS_VIEW = [
  ACTION_JOB_POD_ENRICHER,
  ACTION_POD_GRAPH,
  ACTION_PODS_GET,
  ACTION_GET_YAML,
];

export const ACTIONS_PODS_VIEW = [
  ACTION_POD_RESTART,
  ACTION_POD_GRAPH,
  ACTION_POD_LOGS,
  ACTION_PODS_GET,
];
export const ACTIONS_SILENCES_VIEW = [
  ACTION_SILENCE_GET,
  ACTION_SILENCE_CREATE,
  ACTION_SILENCE_DELETE,
];
export const ALL_ACTIONS = [
  ACTION_POD_RESTART,
  ACTION_POD_LOGS,
  ACTION_PODS_GET,
  ACTION_POD_GRAPH,
  ACTION_SILENCE_DELETE,
  ACTION_SILENCE_CREATE,
  ACTION_SILENCE_GET,
];

interface IClusterAuth {
  isActionsAuthOnCluster: (
    clusterName: string,
    actionsNames: string[]
  ) => boolean;
  clusterHasLightActionSupport: (clusterName: string) => boolean;
  isLoading: ComputedRef<boolean>;
}

const clusterAuthClustersStatus = ref()

export function useClusterAuth(): IClusterAuth {
  if(clusterAuthClustersStatus.value == undefined) {
    clusterAuthClustersStatus.value = useClustersStatuses();
  }
  const clustersStatuses = clusterAuthClustersStatus.value

  const isLoading = computed<boolean>(
    () => clustersStatuses.firstLoadPending
  );

  function isActionInClusterStatus(
    clusterName: string,
    actionsNames: string[]
  ) {
    const supportedClusters = clustersStatuses.data?.filter(
      (c) =>
        c.cluster_id === clusterName &&
        actionsNames.every((action) => c.light_actions.includes(action))
    );
    return supportedClusters != undefined && supportedClusters.length > 0;
  }

  function clusterHasLightActionSupport(clusterName: string) {
    const supportedClusters = clustersStatuses.data?.filter(
      (c) =>
        c.cluster_id === clusterName &&
        isClusterSupported(MIN_LIGHT_ACTIONS_VERSION, c)
    );
    return supportedClusters != undefined && supportedClusters.length > 0;
  }

  function isActionsAuthOnCluster(clusterName: string, actionsNames: string[]) {
    if (clustersStatuses.firstLoadPending || !clusterHasLightActionSupport(clusterName)) {
      return false;
    }
    return isActionInClusterStatus(clusterName, actionsNames);
  }

  return {
    isActionsAuthOnCluster,
    clusterHasLightActionSupport,
    isLoading,
  };
}

function doesClusterHaveIssue(clusterStatus, actions: string[]) {
  if (clusterStatus == undefined) {
    return false;
  }
  if (!isClusterAlive(clusterStatus)){
    return true;
  }
  const clusterAuth = useClusterAuth()
  return !clusterAuth.isActionsAuthOnCluster(clusterStatus.cluster_id, actions);
}

export function getAuthIssuesForClusters(actions: string[]): Array<Notification & { cluster: string }> {
    if(!clusterAuthClustersStatus.value?.data?.length)
      return []

    const clusterStatusesWithIssues = clusterAuthClustersStatus.value.data
      .filter(status => doesClusterHaveIssue(status, actions))

    return clusterStatusesWithIssues.map(clusterStatus => {
      if(!isClusterAlive(clusterStatus)) {
        return {
          cluster: clusterStatus.cluster_id,
          msg: CLUSTER_NOT_CONNECTED_MSG,
          action: contactUsAction(),
          type: NotificationType.ERROR
        }
      }

      const isUpgradeIssue = !isClusterSupported(MIN_LIGHT_ACTIONS_VERSION, clusterStatus)

      return isUpgradeIssue
        ? { cluster: clusterStatus.cluster_id, msg: UPGRADE_MSG, action: upgradeClusterAction(), type: NotificationType.WARNING }
        : { cluster: clusterStatus.cluster_id, msg: CONTACT_US_MSG, action: contactUsAction(), type: NotificationType.ERROR }
    })
}
