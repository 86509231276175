import { DateTime } from 'luxon'
import { ref } from 'vue'
import { getAccountBillingData } from './plan-and-billing.data'
import supabase from '@/store/supabase-client'
import { LAST_15_MINUTES } from '@/store/DatetimeFilter'
import { getUserMonetisationEnabled } from './plan-and-billing.data'

const role = ref()

export async function modifyTimeRangeIfOverFreePlan(dateFilter: {
  startDate: Date
  endDate: Date
}) {
  let { startDate, endDate } = { ...dateFilter }

  try {
    const monetisationEnabled = await getUserMonetisationEnabled()

    if (monetisationEnabled) {
      if (!role.value) {
        const data = await getAccountBillingData(supabase.accountId!)
        role.value = data.plan
      }
  
      if (role.value === 'free') {
        const checkRange = controlTimeRange(startDate, endDate)
  
        startDate = checkRange.startDate
        endDate = checkRange.endDate
      }
    }
  } catch (e) {
    // console.log(e)
  }

  return {
    startDate,
    endDate,
  }
}

function controlTimeRange(start: Date, end: Date) {
  const diff = DateTime.fromJSDate(end).diff(
    DateTime.fromJSDate(start),
    'hours'
  ).hours
  let startDate = start
  let endDate = end
  let isOver = false

  if (diff > 12) {
    const range = LAST_15_MINUTES.toDateRange()
    startDate = range.startDate
    endDate = range.endDate
    isOver = true
    console.log('Trying to access over free plan')
  }

  return { startDate, endDate, isOver }
}
