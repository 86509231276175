import {
  ChartUsageData,
  BillingData,
  PlanTypes,
} from './plan-and-billing.types'
import supabase from '@/store/supabase-client'
import { DateTime } from 'luxon'
import { RELAY_HOST } from '@/store/robusta/data'
import { post, api } from '@/api/request'
import SessionTokenService from '@/store/session-token/data'
import { AccountBillingEmpty } from '@/store/errors'
import { UserType } from '@/store/account/types'
import { userType } from '@/store/rbac/user-permissions/store'
import { trackError, ErrorName } from '@/utils/track-error';

export type BillingDataInfo = { price: number; trial_days: number }

export function getBillingDataInfo(): Promise<BillingDataInfo> {
  return api<BillingDataInfo>(
    `${RELAY_HOST}/billing/data`
  ).catch((e) => {
    trackError(ErrorName.FAILED_TO_GET_BILLING_DATA_INFO);
    return e
  })
}

export function getManageSubscriptionLink() {
  return billingPost<{ url: string }>('portal')
}

export function startTrialAccount() {
  return billingPost<{ success: boolean }>('trial')
}

export function getCheckoutLink() {
  return billingPost<{ url: string }>('checkout')
}

async function billingPost<T>(path: string) {
  if (userType.value === UserType.Support) {
    return Promise.resolve({} as T)
  }
  const account_id = supabase.accountId!
  const user_id = supabase.userId!
  const endpoint = `${RELAY_HOST}/billing/${path}`
  const session_token = await SessionTokenService.getOrCreate(
    user_id,
    account_id
  )
  const data = JSON.stringify({ account_id, session_token })

  return post<T>(endpoint, data).catch((e) => {
    if (path === 'portal') trackError(ErrorName.FAILED_TO_GET_MANAGE_SUBSCRIPTION_LINK);
    else if (path === 'trial') trackError(ErrorName.FAILED_TO_START_TRIAL_ACCOUNT);
    else if (path === 'checkout') trackError(ErrorName.FAILED_TO_GET_CHECKOUT_LINK);

    return e;
  });
}

export async function updateLastSeenBillingAlerts(type) {
  return await supabase.setUserSetting(type, { lastSeen: Date.now() })
}

export async function getPodsUsage(
  accountId: string,
  type: 'hour' | 'day'
): Promise<ChartUsageData[]> {
  const rpcMap = {
    hour: {
      rpc: 'pod_count_to_hour_graph',
      fromDate: '1 day',
    },
    day: {
      rpc: 'avg_pod_count_to_day_graph',
      fromDate: '1 month',
    },
  }

  const { rpc, fromDate } = rpcMap[type]

  const { error, data } = await supabase.client.rpc(rpc, {
    _account_id: accountId,
    _duration: fromDate,
  })

  if (error) {
    trackError(ErrorName.FAILED_TO_GET_PODS_USAGE);
    throw Error(`Error getting billing usage`)
  }

  return data as ChartUsageData[]
}

export async function getAccountBillingData(accountId: string) {
  const monetisationEnabled = await getUserMonetisationEnabled()

  if (!monetisationEnabled) {
    return {
      plan: 'free',
      trial_start: null,
      trial_end: null,
    } as BillingData
  }

  let { error, data } = await supabase.client
    .from<BillingData>('AccountBilling')
    .select()
    .eq('account_id', accountId)

  // Table record not created yet
  if (!data || !data[0]) {
    // start trial
    throw new AccountBillingEmpty()
  } else if (error) {
    trackError(ErrorName.FAILED_TO_GET_ACCOUNT_BILLING_DATA);
    return Promise.reject({ plan: 'free' } as BillingData)
  }

  data[0].plan = calculatePlan(data[0])

  return data[0] as BillingData
}

export async function getUserMonetisationEnabled() {
  const SELF_HOSTED = (window as any).ENV_SELF_HOSTED as boolean
  return !SELF_HOSTED
}

export function isProInTrial(data: BillingData) {
  let { plan } = data
  plan = plan.toLowerCase() as PlanTypes

  return plan === 'pro' && isTrial(data)
}

function calculatePlan(data: BillingData) {
  let { plan } = data
  plan = plan.toLowerCase() as PlanTypes
  if (plan === 'pro' || plan === 'custom') return plan

  return isTrial(data) ? 'trial' : plan
}

function isTrial(data: BillingData) {
  const trialStart = DateTime.fromISO(data.trial_start).toMillis()
  const trialEnd = DateTime.fromISO(data.trial_end).toMillis()
  const now = DateTime.now().toMillis()
  const isTrial = trialStart < now && now < trialEnd

  return isTrial
}
