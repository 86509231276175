import supabase from "@/store/supabase-client"
import { generateRandomUUID } from '@/utils/string'
import { trackError, ErrorName } from '@/utils/track-error';

export async function createSessionToken(userId: string, accountId: string): Promise<string> {
  const sessionToken = generateRandomUUID()

  const values = {
    user_id: userId,
    account_id: accountId,
    token: sessionToken
  }

  const { error } = await supabase.client
    .from("FrontendSessionTokens")
    .insert([values], { returning: "minimal" }) // Return minimal otherwise we'll get an error (we're not allowed to read from this table)

  if (error) {
    trackError(ErrorName.FAILED_TO_CREATE_SESSION_TOKEN);
    throw new Error('Error generating session token')
  }

  return sessionToken
}

export async function createAuthToken(userId: string, accountId: string, type: string): Promise<string> {
  const token = generateRandomUUID()
  const values = {
    user_id: userId,
    account_id: accountId,
    token: token,
    type: type
  }

  const { error } = await supabase.client
    .from("AuthTokens")
    .insert([values], { returning: "minimal" }) // Return minimal otherwise we'll get an error (we're not allowed to read from this table)

  if (error) {
    trackError(ErrorName.FAILED_TO_CREATE_AUTH_TOKEN);
    throw Error("Error generating session token.");
  }

  return token
}
