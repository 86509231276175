import { getServiceGroupingKey } from "@/utils/services"
import { getImageShortName } from "@/components/services/service-logic"
import { definitions } from "@/store/supabase-autogenerated"
import { Service } from "@/store/services/types"
import { flattenArrayOfObjects } from "@/utils/array"

export function mapRawService(rawService: definitions["Services"]): Service {
  const service = { ...rawService } as Service

  service.groupingKey = getServiceGroupingKey(rawService.type, rawService.name)

  service.getContainerByName = function(name: string) {
    if(!rawService.config || !rawService.config.containers)
      return null
    return rawService.config.containers.filter(c => c.name === name)[0] || null
  }

  // Add a unique key for each container.
  // Used in cluster comparison to sync heights, but might be used somewhere else in the future.
  service.config = service.config ?? {}
  service.config.labels = service.config.labels ?? {}
  service.config.containers = (service.config.containers ?? []).map(c => {
    return {
      ...c,
      key: `${service.cluster}/${service.namespace}/${service.type}/${service.name}/${c.name}`,
      imageShortName: c.image ? getImageShortName(c.image) : undefined,
      numberOfEnvVars: c.env?.length ?? 0,
      env: flattenArrayOfObjects(c.env ?? [])
    }
  })

  return service
}
