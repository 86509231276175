import { createSessionToken } from "@/store/session-token/controllers/api"
import SessionTokenLocalStore from "@/store/session-token/controllers/store"
import { SessionToken } from "@/store/session-token/types"


const TOKEN_TTL = 50 * 60 * 1000


export default class SessionTokenService {
  static async getOrCreate(userId: string, accountId: string, forceCreate = false): Promise<string> {
    if(!forceCreate) {
      const sessionToken = SessionTokenLocalStore.get(userId, accountId)

      if(isSessionTokenValid(sessionToken)) { // Return existing token if valid
        // @ts-ignore
        return sessionToken.token
      }
    }

    // No valid token available - create e new one
    const tokenStr = await createSessionToken(userId, accountId)
    SessionTokenLocalStore.save(userId, accountId, tokenStr)
    return tokenStr
  }
}


function isSessionTokenValid(sessionToken: SessionToken | undefined): boolean {
  return sessionToken
    ? _now() - sessionToken.createdAt < TOKEN_TTL
    : false
}

function _now() {
  return Date.now()
}
