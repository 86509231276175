import { ManualAction } from "@/manual-actions/types"
import { trackEvent } from "@/analytics"

export class ManualActionResult<Data> {
  accountId: string
  data?: Data
  error?: Error

  constructor(accountId: string, data?: Data, error?: Error) {
    this.accountId = accountId
    this.data = data
    this.error = error
  }
}

export async function runManualAction<Data>(
  fetcher: ManualAction<Data>,
  userId: string,
  accountId: string,
  sessionToken: string
): Promise<ManualActionResult<Data>> {
  // We do this result trick because we need to specify the account ID this result belongs to,
  // in case it was changed during the run (ideally we'd guarantee it won't)
  const result = new ManualActionResult<Data>(accountId)

  if (!userId || !accountId || !sessionToken) {
    result.error = new Error("Empty mandatory input")
    const emptyFields : string[] = []
    if (!userId) emptyFields.push("userId")
    if (!accountId) emptyFields.push("accountId")
    if (!sessionToken) emptyFields.push("sessionToken")
    trackError(result.error, { emptyFields })
    return result
  }


  let response

  try {
    response = await fetcher(accountId, sessionToken)
  } catch (exc) {
    const err = exc as Error
    trackError(err, { message: err.message })

    result.error = err
    return result
  }

  result.data = response
  return result
}

function trackError(error: Error, props?) {
  trackEvent("Manual Action Fetcher Failed", {
    errorName: error.name,
    errorMessage: error.message,
    ...props
  })
}
