import { PlanTypes } from "@/plan-and-billing/plan-and-billing.types"
import { computed, ComputedRef, ref } from "vue"
import { getAccountBillingData } from "@/plan-and-billing/plan-and-billing.data"
import supabase from "@/store/supabase-client"
import { IS_SELF_HOSTED } from "@/consts"
import { AccountBillingEmpty } from "@/store/errors"
import { trackEvent } from "@/analytics"

const _plan = ref<PlanTypes>()

export interface IAccountStore {
  plan: ComputedRef<PlanTypes | undefined>

  /**
   * Recalculates the state for the current active account.
   */
  update: () => Promise<void>
}

export function useAccountStore(): IAccountStore {
  async function update() {
    _plan.value = await _getPlan()
  }

  return {
    plan: computed(() => _plan.value),
    update
  }
}

async function _getPlan(): Promise<PlanTypes> {
  if(IS_SELF_HOSTED)
    return "custom"

  try {
    return (await getAccountBillingData(supabase.accountId!)).plan
  } catch(err) {
    if(err instanceof AccountBillingEmpty)
      return "trial"

    trackEvent("Failed to get account's plan",{ error: err })
    return "free" // This fallback guarantees the user isn't getting more features than he should.
  }
}