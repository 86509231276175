import { DateTime as LuxonDateTime } from 'luxon'

export enum DatetimeFilterType {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute'
}

const FMT_SHORT = "MMM d"
const FMT_LONG = "MMM d HH:mm"

const getFormattedRange = (startDate: Date, endDate: Date) => {
  if(isBeginningOfDay(startDate) && isEndOfDay(endDate)) {
    return startDate.toDateString() === endDate.toDateString()
      ? format(startDate, FMT_SHORT)
      : `${format(startDate, FMT_SHORT)} - ${format(endDate, FMT_SHORT)}`
  } else
    return `${format(startDate, FMT_LONG)} - ${format(endDate, FMT_LONG)}`
}

function isBeginningOfDay(date: Date) {
  return date.getHours() === 0 && date.getMinutes() === 0
}

function isEndOfDay(date: Date) {
  return date.getHours() === 23 && date.getMinutes() === 59
}

function format(date: Date, fmt) {
  return LuxonDateTime.fromJSDate(date).toFormat(fmt)
}


export class DatetimeFilter {
  type: DatetimeFilterType
  humanReadable: string
  startDate: number | Date
  endDate?: Date

  constructor(type: DatetimeFilterType, humanReadable: string, startDate: number | Date, endDate?: Date) {
    this.type = type
    this.humanReadable = humanReadable
    this.startDate = startDate
    this.endDate = endDate
  }

  isRelative() {
    return this.type === DatetimeFilterType.RELATIVE
  }

  toDateRange() {
    if(this.isRelative()) {
      return {
        startDate: LuxonDateTime.now().minus({ seconds: this.startDate }).toJSDate() as Date,
        endDate: LuxonDateTime.now().toJSDate() as Date
      }
    } else {
      return {
        startDate: this.startDate as Date,
        endDate: this.endDate as Date
      }
    }
  }

  /**
   * @param humanReadable a helpful description (Last 15 minutes)
   * @param interval num of seconds relative to now (900 = now - 15 minutes)
   */
  static createRelative(humanReadable: string, interval: number) {
    return new DatetimeFilter(DatetimeFilterType.RELATIVE, humanReadable, interval)
  }

  static createAbsolute(startDate: Date, endDate: Date) {
    return new DatetimeFilter(DatetimeFilterType.ABSOLUTE, getFormattedRange(startDate, endDate), startDate, endDate)
  }
}

export const LAST_15_MINUTES = DatetimeFilter.createRelative('Last 15 min', 900)
export const LAST_1_HOUR = DatetimeFilter.createRelative('Last 1 hr', 3600)
export const LAST_3_HOURS = DatetimeFilter.createRelative('Last 3 hrs', 10800)
export const LAST_4_HOURS = DatetimeFilter.createRelative('Last 4 hrs', 12000)
export const LAST_6_HOURS = DatetimeFilter.createRelative('Last 6 hrs', 21600)
export const LAST_12_HOURS = DatetimeFilter.createRelative('Last 12 hrs', 43200)
export const LAST_24_HOURS = DatetimeFilter.createRelative('Last 24 hrs', 86400)
export const LAST_WEEK = DatetimeFilter.createRelative('Last week', 604_800)
export const LAST_MONTH = DatetimeFilter.createRelative('Last month', 2_629_743)

export const ALL_RELATIVE_DATE_FILTERS = [
  LAST_15_MINUTES,
  LAST_1_HOUR,
  LAST_3_HOURS,
  LAST_4_HOURS,
  LAST_6_HOURS,
  LAST_12_HOURS,
  LAST_24_HOURS,
  LAST_WEEK,
  LAST_MONTH,
]
