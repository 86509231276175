import supabase from "@/store/supabase-client"
import { RouteName, RouterBuilder } from "@/router/router-builder"
import { NavigationGuardNext, Route } from "vue-router"

export default async function accountBasedRoutesGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  const requestedAccount = supabase.accounts.find(
    (account) => account.name === to.params.account,
  )

  if (!to.matched.some(r => r.meta.accountBased)) {
    return next()
  }

  if (to.params.account && !requestedAccount) {
    return next(RouterBuilder.getAccountAccessRequestRoute({
      account: to.params.account,
    }))
  }

  if (!supabase.accounts.length) {
    return next(RouterBuilder.getOnBoardingRoute())
  }

  if(!to.params.account) {
    const redirectAccount =
      supabase.activeAccount ??
      // Legacy code that allows to set active account using query param
      supabase.accounts.find(
        (account) =>
          [to.query.accountName, to.query.account_name, to.query.account].includes(account.name) ||
          [to.query.accountId, to.query.account_id, to.query.account].includes(account.id),
      ) ??
      // Try to get the last account that was active
      supabase.accounts.find(account => {
        return account.id === supabase.savedActiveUserAccounts[supabase.userId]
      }) ??
      supabase.accounts[0]

    return next({
      name: <string>to.name,
      params: {
        ...to.params,
        account: redirectAccount.name,
      },
      query: to.query,
      hash: to.hash,
    })
  }

  if (!requestedAccount) {
    return next({
      name: RouteName.NotFound,
      query: to.query,
      hash: to.hash,
    })
  }

  await supabase.setActiveAccount(requestedAccount)
  next()
}