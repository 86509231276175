import { Finding, FindingPriority } from "./findings/data";

const findingPriorityKeys = Object.keys(FindingPriority)

export function sortIssuesByPriority(
  a: Finding,
  b: Finding,
  isAscending: boolean
): number {
  if (!a.priority || !b.priority) {
    console.log("bad priority!", a, b);
  }

  const aIndex = findingPriorityKeys.indexOf(a.priority)
  const bIndex = findingPriorityKeys.indexOf(b.priority)

  return isAscending ? bIndex - aIndex : aIndex - bIndex
}

// returns the bulma CSS class for a given priority
export function getCSSForPriority(priority: string): string {
  if (!priority) {
    console.warn(`called getCSSForPriority with '${priority}'`);
    return "";
  }
  priority = priority.toLowerCase();
  switch (priority) {
    case "high":
      return "is-danger";
    case "medium":
      return "is-danger is-light";
    case "low":
      return "is-warning";
    case "debug":
      return "is-warning is-light"
    // info
    default:
      return "is-warning";
  }
}

export function returnUIActionData(evidence) {
  const result = JSON.parse(evidence.data)

  return result[0].data
}

export function priorityToSeverity(priority: number): string {
  switch (priority) {
    case 0:
      return 'unknown'
    case 1:
      return 'good'
    case 2:
      return 'ok'
    case 3:
      return 'warning'
    case 4:
      return 'critical'
    default:
      return ''
  }
}

export function vRemoveFromVersion(str?: string) {
  if (str) {
    if (str[0] === 'v' || str[0] === 'V') {
      str = str.substring(1);
    }
    if (str.length > 7) {
      str = str.substring(0, 7);
    }
    if (str.charAt(str.length - 1) === '-' || str.charAt(str.length - 1) === '-') {
      str = str.slice(0, -1);
    }
  }
  return str;
}