import { ALL_RELATIVE_DATE_FILTERS, DatetimeFilter } from "@/store/DatetimeFilter"

type EncodedDatetimeFilter =
  number | // Relative filter
  [string, string] // Absolute filter

export function encodeDatetimeFilter(datetimeFilter: DatetimeFilter): EncodedDatetimeFilter | undefined {
  try {
    if(datetimeFilter.isRelative())
      return datetimeFilter.startDate as number
    else {
      const { startDate, endDate } = datetimeFilter.toDateRange()
      return [startDate.toISOString(), endDate.toISOString()]
    }
  } catch {
    return undefined
  }
}

export function decodeDatetimeFilter(encoded: EncodedDatetimeFilter): DatetimeFilter | undefined {
  try {
    if(isProbablyRelative(encoded))
      return ALL_RELATIVE_DATE_FILTERS.find(d => d.startDate === encoded) || undefined

    if(isProbablyAbsolute(encoded)) {
      const [startISO, endISO] = encoded as [string, string]
      const startDate = new Date(startISO)
      const endDate = new Date(endISO)

      const isInvalid = isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || startDate > endDate
      return isInvalid ? undefined : DatetimeFilter.createAbsolute(startDate, endDate)
    }

    return undefined
  } catch {
    return undefined
  }
}

function isProbablyRelative(encoded: EncodedDatetimeFilter): boolean {
  return typeof(encoded) === "number"
}

function isProbablyAbsolute(encoded: EncodedDatetimeFilter): boolean {
  return Array.isArray(encoded)
    && encoded.length === 2
    && typeof(encoded[0]) === "string"
    && typeof(encoded[1]) === "string"
}
