import { SessionToken } from "@/store/session-token/types"


const TOKEN = "token"
const CREATED_AT = "createdAt"


export default class SessionTokenLocalStore {
  static _storage = sessionStorage

  static save(userId: string, accountId: string, sessionToken: string): void {
    const obj = {}
    obj[TOKEN] = sessionToken
    obj[CREATED_AT] = Date.now()

    const key = _getStorageKey(userId, accountId)
    this._storage.setItem(key, JSON.stringify(obj))
  }

  static get(userId: string, accountId: string): SessionToken | undefined {
    const key = _getStorageKey(userId, accountId)

    const data = this._storage.getItem(key)
    if(!data) {
      return undefined
    }

    const obj = JSON.parse(data)

    if(typeof(obj) !== "object" || !obj[TOKEN] || !obj[CREATED_AT]) {
      return undefined
    }

    return new SessionToken(obj[TOKEN], obj[CREATED_AT])
  }
}


function _getStorageKey(userId: string, accountId: string): string {
  if (!userId || !accountId) throw new Error("Cannot save a session token for empty user or account")
  return `${userId}:${accountId}:sessionToken`
}
