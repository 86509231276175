import { fetchServices } from "@/store/services/data"
import { fetchClustersStatuses } from "@/store/cluster/data"

export class AccountSetupService {
  static async isSetupCompleted(accountId: string) {
    const [clusters, services] = await Promise.all([
      fetchClustersStatuses(accountId, { filterDead: false, limit: 1 }),
      fetchServices(accountId, { getDeleted: true, filterDead: false, limit: 1 })
    ])

    return clusters.length > 0 && services.length > 0
  }
}