export function useUserAgent () {
  const userAgent = navigator.userAgent.toLowerCase()

  const getBrowserType = () => {
    if (userAgent.indexOf('chrome') > -1) {
      return 'Chrome'
    } else if (userAgent.indexOf('firefox') > -1) {
      return 'Firefox'
    } else if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1) {
      return 'Safari'
    } else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
      return 'Opera'
    } else if (userAgent.indexOf('edge') > -1) {
      return 'Edge'
    } else {
      return 'Unknown'
    }
  }

  function getOS() {
    if (userAgent.indexOf('windows nt') !== -1) {
      return 'windows';
    } else if (userAgent.indexOf('macintosh') !== -1 || userAgent.indexOf('mac os x') !== -1) {
      return 'macos';
    } else if (userAgent.indexOf('linux') !== -1) {
      return 'linux';
    } else if (userAgent.indexOf('android') !== -1) {
      return 'android';
    } else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1 || userAgent.indexOf('ipod') !== -1) {
      return 'ios';
    } else {
      return 'unknown';
    }
  }

  return {
    getBrowserType,
    getOS
  }
}
