/**
 * A function the sends ManualActionRequests to the relay, and handles the responses of those actions.
 *
 * E.g. the function sends a request to get the logs of a specific pod, parses the relay's response
 * and returns the logs back.
 */
export type ManualAction<Data> = (accountId: string, sessionToken: string) => Promise<Data | undefined>

export type Sinks = 'robusta_ui_sink'

export type ManualActionRequestOptions = {
  sinks: Sinks[]
  async: boolean
}

export const DEFAULT_MANUAL_ACTION_REQUEST_OPTIONS = {
  sinks: [],
  async: false
}

/**
 * Represents a request for the relay to run a manual action on user's cluster.
 */
export class ManualActionRequest {
  accountId: string
  clusterName: string
  actionName: string
  actionParams: Record<string, any>
  options: ManualActionRequestOptions

  constructor(
    accountId: string,
    clusterName: string,
    actionName: string,
    actionParams: Record<string, any>,
    options?: Partial<ManualActionRequestOptions>
  ) {
    this.accountId = accountId
    this.clusterName = clusterName
    this.actionName = actionName
    this.actionParams = actionParams
    this.options = { ...DEFAULT_MANUAL_ACTION_REQUEST_OPTIONS, ...options }
  }
}
