import { watch, onMounted, onBeforeUnmount } from 'vue'
import _ from 'lodash'
import router from '@/router'

type SyncQueryParam = {
  id: string,
  get: () => any,
  set?: (value: any) => void
}

function isEmptyValue(value) {
  if(value === undefined)
    return true
  if(value === null)
    return true
  if(value === '')
    return true
  if(Array.isArray(value) && value.length === 0)
    return true
  return false
}

export function serialize(data) {
  try {
    return JSON.stringify(data)
  } catch {
    return undefined
  }
}

export function deserialize(data) {
  try {
    return JSON.parse(data)
  } catch {
    return undefined
  }
}

const registeredParams: SyncQueryParam[] = []
const changeQueue = [] as any[]
let unwatch
async function nextChange() {
  if(changeQueue.length) {
    const change = changeQueue.shift()
    const newQuery = { ...router.currentRoute.query, ...change }
    if(!_.isEqual(router.currentRoute.query, newQuery)) {
      await router.replace({ query: newQuery })
    }
  }
  requestAnimationFrame(nextChange)
}
nextChange()
function read(params: SyncQueryParam[]) {
  params.forEach((param) => {
    const queryValue = router.currentRoute.query[param.id]
    if(queryValue && param.set) {
      // Note we made param "set" method optional at some point, because we should shift to
      // using route "props" feature for this.
      // For more info see https://router.vuejs.org/guide/essentials/passing-props#Function-mode
      param.set(deserialize(queryValue))
    }
  })
}
function sync() {
  const change = registeredParams.reduce((result, param) => {
    const stateValue = param.get()
    if(!isEmptyValue(stateValue)) {
      result[param.id] = serialize(stateValue)
    } else {
      result[param.id] = undefined
    }
    return result
  }, {})
  changeQueue.push(change)
}
function rebuildWatcher() {
  if(unwatch) {
    unwatch()
    unwatch = undefined
  }
  unwatch = watch(registeredParams.map((param) => param.get), () => sync(), { deep: true })
}
function registerParams(params: SyncQueryParam[]) {
  params.forEach((param) => registeredParams.push(param))
  read(params)
  sync()
  rebuildWatcher()
}
function unregisterParams(params: SyncQueryParam[]) {
  params.forEach((param) => {
    const paramIndex = registeredParams.findIndex((p) => p.id === param.id)
    if(paramIndex !== -1) {
      registeredParams.splice(paramIndex, 1)
    }
  })
  rebuildWatcher()
}

export function useSyncQueryParams(params: SyncQueryParam[]): void {
  onMounted(() => {
    registerParams(params)
  })
  onBeforeUnmount(() => {
    unregisterParams(params)
  })
}
