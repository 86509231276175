import useSWRV, { IConfig as SWRVConfig } from "swrv"
import { Ref, computed } from 'vue'
import { useAsyncState, useLastChanged } from "@vueuse/core"
import supabase from "@/store/supabase-client"
import { IResponse } from "swrv/dist/types";

export interface IAPIResponse<Data> {
  data: Ref<Data | undefined>
  error: Ref<Error | undefined>
  isValidating: Ref<boolean>
  refresh: () => Promise<void>
  lastChanged: Ref<number|null>
  firstLoadPending: Ref<boolean>
}

export type CacheKey = string | (() => string)

const DEFAULT_SWRV_CONFIG = {
  revalidateOnFocus: false,
  refreshInterval: 60 * 1000,
  dedupingInterval: 30 * 1000
}

export function generateFilterBasedCacheKey(filter: any): string {
  const filterType = typeof(filter)
  if(filterType === 'object' && Array.isArray(filter)) {
    return filter
      .map(generateFilterBasedCacheKey)
      .sort()
      .toString()
  } else if(filterType === 'object' && filter !== null) {
    const sortedKeys = Object.keys(filter).sort()
    const newEntries = sortedKeys.map(key => [key, generateFilterBasedCacheKey(filter[key])])
    return JSON.stringify(
      Object.fromEntries(newEntries)
    )
  } else {
    return String(filter)
  }
}

function getCacheKeyForActiveAccount(cacheKey: CacheKey) {
  const accountId = supabase.accountId
  if(!accountId) {
    return undefined
  }

  const cacheKeyString = typeof cacheKey === 'function' ? cacheKey() : cacheKey
  if(!cacheKeyString) {
    return undefined
  }

  return `${accountId}:${cacheKeyString}`
}

export function getFirstLoadPending(swrvResponse: IResponse<any,any>) : Ref<boolean> {
  return computed(() => swrvResponse.data.value === undefined && ! swrvResponse.error.value)
}

/**
 * Gets data using the passed fetcher, and stores is in a cache under the active account.
 * Whenever the active account changes, the response data is reset to the new active account's data of the same cache name.
 *
 * @param cacheKey
 * @param fetcher: A wrapper for the actual function that gets the data.
 *  It's needed because SWRV assumes the fetcher fn uses the first param as
 *  the URL for an HTTP requests. Since we're not using HTTP, we need to
 *  bypass this. So we simply ignore it and call a custom (e.g. supabase) fetcher instead.
 */

export default function useGetDataForActiveAccount<Data>(
  cacheKey: CacheKey,
  fetcher: () => Promise<Data>,
  config?: SWRVConfig
): IAPIResponse<Data> {
  /**
   * SWRV = stale while revalidate
   *
   * 1. Why do we give SWRV a function to compute the cache key, instead the of key itself?
   *      Because it enables us to easily manage a client side store per account ID.
   *      This function has a reactive dependency, so SWRV watches its return value.
   *      whenever the accountID changes -
   *        => The return value changes
   *        => SWRV starts to operate in the context of a new cache key (which is account based)
   *        => Stale: SWRV updates "response.data" to whatever is stored in the cache for the new key
   *            * At this point, the view will be updated with the stale data
   *        => Revalidate: SWRV runs the fetcher, and updates the cache with fresh data returned from the server
   * 2. SWRV won't fire the fetcher if the cache key function returns a falsy key
   */
  const swrvConfig = { ...DEFAULT_SWRV_CONFIG, ...(config || {}) }
  const response = useSWRV(() => getCacheKeyForActiveAccount(cacheKey), fetcher, swrvConfig)
  const responseTime = useLastChanged(response.data); // Updates whenever data is reloaded, even if it's reloaded with the exact same value.
  const firstLoadPending = getFirstLoadPending(response);
  /**
   * Sends a new API request to get fresh data.
   * response.data & the cache are both updated with the new data.
   * Use this when you want to get new data manually.
   */
  function refresh() {
    return response.mutate()
  }

  return {
    data: response.data,
    error: response.error,
    isValidating: response.isValidating,
    refresh,
    firstLoadPending,
    lastChanged: responseTime
  }
}


export type GetDataNoCacheOpts = {
  resetOnExecute: boolean
}
export function useGetDataWithoutCache<Data>(fetcher: () => Promise<Data>, opts?: GetDataNoCacheOpts): IAPIResponse<Data> {
  const response = useAsyncState(fetcher, undefined, {
    resetOnExecute: opts?.resetOnExecute || false
  })
  const responseTime = useLastChanged(response.state)

  return {
    data: response.state,
    error: response.error as Ref<Error | undefined>,
    isValidating: computed(() => !response.isReady.value),
    refresh: async () => {
      await response.execute()
    },
    firstLoadPending: computed(() => response.state.value === undefined && !response.error.value),
    lastChanged: responseTime
  }
}
