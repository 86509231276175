import { NavigationGuardNext, Route } from "vue-router"

export default function supabaseHashCleanupGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  if (!to.hash.startsWith('#access_token=')) {
    return next()
  }

  next({
    name: <string>to.name,
    params: to.params,
    query: to.query,
    hash: undefined,
  })
}