// Standard variation
export function api<T>(url: string): Promise<T> {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json() as Promise<T>
    })
}

export function post<T>(url: string, data: string): Promise<T> {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
    },
    body: data
  };
  return fetch(url, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json() as Promise<T>
    })
}
