import supabase from "@/store/supabase-client"
import { definitions } from "../supabase-autogenerated"
import { USER_ROLE_API } from "./constants"
import { UserType } from './types'
import { trackError, ErrorName } from '@/utils/track-error';

export type AccountRequest = definitions["AccountUserRequests"]
export type AccountUser = definitions["AccountUsers"]
export type AccountType = definitions["AccountTypes"]
export type Account = definitions["Accounts"]

export async function fetchAccountType(): Promise<AccountType | undefined> {
  const accountTypeID = supabase.activeAccount?.account_type;
  if (accountTypeID == undefined) {
    return undefined;
  }
  const request = supabase.client.from<AccountType>("AccountTypes").select("*").eq("id", accountTypeID)

  const response = await request;
  if (!response.data || response.data.length != 1) {
    trackError(ErrorName.FAILED_TO_FETCH_ACCOUNT_TYPE);
    throw Error(`Error getting account types`);
  }

  return response.data[0];
}

export async function fetchAllUsersOnAccount(): Promise<readonly AccountUser[]> {
  const request = supabase.client
    .from<AccountUser>("AccountUsers")
    .select("*")
    .eq("account_id", supabase.accountId)
    .not('role', 'eq', USER_ROLE_API)
    .order('role', { ascending: true })
    .order('user_name', { ascending: true });

  const response = await request;
  if (!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_ALL_USERS_ON_ACCOUNT);
    throw Error(`Error getting users on account`);
  }

  return response.data;
}

export async function fetchUserType(accountId: string): Promise<UserType> {
  const { error, data } = await supabase.client.rpc('get_user_type', {
    _account_id: accountId
  })

  if(error || !data) {
    trackError(ErrorName.FAILED_TO_FETCH_USER_TYPE);
    throw new Error('Error getting user type')
  }

  return data as unknown as UserType
}

export async function fetchAllUserRequests(): Promise<AccountRequest[]> {
  const request = supabase.client
    .from<AccountRequest>("AccountUserRequests")
    .select("*").eq("deleted", false).not("status", "eq", "SUCCESS");

  const response = await request;
  if (!response.data) {
    trackError(ErrorName.FAILED_TO_FETCH_ALL_USER_REQUESTS);
    throw new Error('Error getting user requests')
  }

  return response.data;
}

export async function createJoinAccountRequest(emailToAdd: string, userRole: string): Promise<boolean> {
  const accountID = supabase.accountId;
  const response = await supabase.client
    .rpc('create_join_account_request', {
      _account_id: accountID, _email: emailToAdd.toLowerCase(), _role: userRole.toUpperCase()
    })

  if (!response.data) {
    trackError(ErrorName.FAILED_TO_CREATE_JOIN_ACCOUNT_REQUEST);
    throw Error(`error creating account request.`);
  }

  return response.data as unknown as boolean;
}

export async function deleteAccountRequest(requestIdToDelete: string): Promise<boolean> {
  const response = await supabase.client
    .rpc('delete_account_request', { '_request_id': requestIdToDelete });

  if (!response.data) {
    trackError(ErrorName.FAILED_TO_DELETE_ACCOUNT_REQUEST);
    throw Error(`error deleting account request.`);
  }

  return response.data as unknown as boolean;
}

export async function respondToAccountRequest(requestId: string, accepted: boolean): Promise<boolean> {
  const response = await supabase.client
    .rpc('respond_to_join_account_request', {
      _request_id: requestId,
      _accepted: accepted ? 1 : 0
    })


  if (!response.data) {
    trackError(ErrorName.FAILED_TO_RESPOND_TO_ACCOUNT_REQUEST);
    throw Error(`error responding to account request.`);
  }

  return response.data as unknown as boolean;
}

export async function fetchUserAccounts(): Promise<Account[]> {
  const { data, error } = await supabase.client
    .from<Account>("Accounts")
    .select("*")

  if(!data || error) {
    trackError(ErrorName.FAILED_TO_FETCH_USER_ACCOUNTS);
    throw new Error("Failed to fetch accounts")
  }

  return data
}

export async function softDeleteAccount(accountId: string): Promise<boolean> {
  const response = await supabase.client
    .rpc('soft_delete_account', {
      _account_id: accountId,
    })

  if (response.error) {
    trackError(ErrorName.FAILED_TO_SOFT_DELETE_ACCOUNT);
    throw Error("Failed to delete your account. An unexpected error occurred.");
  }

  return response.data as unknown as boolean;
}

export async function renameAccount(accountId: string, newName: string): Promise<boolean> {
  if(accountId.length == 0)
    throw Error('Bad account Id.');

  if(newName.trim().length == 0)
    throw Error('Error: Cant use an empty name.');

  const response = await supabase.client
    .rpc('rename_account', {
      _account_id: accountId,
      _name: newName
    })

  if (response.error) {
    trackError(ErrorName.FAILED_TO_RENAME_ACCOUNT);
    throw Error(`Error: ${response.error.message}`);
  }

  return response.data as unknown as boolean;
}
