import supabase from '@/store/supabase-client'
import { UserSettings, AccountSettings } from './types'
import { trackError, ErrorName } from '@/utils/track-error';

export async function fetchUserSettings(userId: string, accountId: string): Promise<UserSettings> {
  if(!userId || !accountId)
    throw new Error('Missing userId or accountId')

  const { data, error } = await supabase.client
    .from('UserSettings')
    .select('settings')
    .eq('user_id', userId)
    .eq('account_id', accountId)

  if(error) {
    trackError(ErrorName.FAILED_TO_FETCH_USER_SETTINGS);
    throw new Error('Error getting user settings')
  }

  if(!data || !data[0] || !data[0].settings)
    return {}

  return data[0].settings
}

export async function fetchAllUserSettings(userId: string): Promise<UserSettings[]> {
  if(!userId)
    throw new Error('Missing userId')

  const { data, error } = await supabase.client
    .from('UserSettings')
    .select('settings')
    .eq('user_id', userId)

  if(error) {
    trackError(ErrorName.FAILED_TO_FETCH_ALL_USER_SETTINGS);
    throw new Error('Error getting all user settings')
  }

  if(!data)
    return []

  return data.map((row) => {
    if(!row.settings)
      return {}
    return row.settings
  })
}

export async function updateUserSettings(userId: string, accountId: string, settings: UserSettings): Promise<void> {
  if(!userId || !accountId || !settings)
    throw new Error('Missing userId, accountId or settings')

  const { error } = await supabase.client
    .from('UserSettings')
    .upsert({
      user_id: userId,
      account_id: accountId,
      settings,
      updated_at: new Date()
    })

  if(error) {
    trackError(ErrorName.FAILED_TO_UPDATE_USER_SETTINGS);
    throw new Error('Error updating user settings')
  }
}

export async function fetchAccountSettings(accountId: string): Promise<AccountSettings> {
  if(!accountId)
    throw new Error('Missing accountId')

  const { data, error } = await supabase.client
    .from('AccountSettings')
    .select('settings')
    .eq('account_id', accountId)

  if(error)
    throw new Error('Error getting account settings')

  if(!data || !data[0] || !data[0].settings)
    return {}

  return data[0].settings
}

export async function updateAccountSettings(accountId: string, settings: AccountSettings): Promise<void> {
  if(!accountId || !settings)
    throw new Error('Missing accountId or settings')

  const { error } = await supabase.client
    .from('AccountSettings')
    .upsert({
      account_id: accountId,
      settings,
      updated_at: new Date()
    })

  if(error)
    throw new Error('Error updating account settings')
}
