import supabase from "@/store/supabase-client"
import { Permission, PermissionActions, PermissionDefinition } from "@/store/rbac/permissions-metadata/types"
import { ScopeType } from "@/store/rbac/scopes/types"
import { trackError, ErrorName } from '@/utils/track-error';

export async function getPermissionDefinitions(): Promise<PermissionDefinition[]> {
  const { data, error } = await supabase.client
    .from("PermissionDefinitions")
    .select(`
      permission_id,
      name,
      resource,
      description,
      position,
      scope_type
    `)

  if(error || !data) {
    trackError(ErrorName.FAILED_TO_GET_PERMISSION_DEFINITIONS);
    throw new Error("Unable to get permission definitions!")
  }
    

  return data.map(def => Object.freeze({
    id: def.permission_id as Permission,
    name: def.name,
    resource: def.resource,
    description: def.description,
    position: def.position,
    scopeType: def.scope_type as ScopeType
  }))
}

/**
 * Returns a map between permissions and their required actions.
 * A permission is only granted to a user if he's authorized to run all its required actions.
 */
export async function getPermissionActions(): Promise<PermissionActions> {
  const {data, error} = await supabase.client
    .from("PermissionActions")
    .select("permission_id,actions")

  if (error || !data) {
    trackError(ErrorName.FAILED_TO_GET_PERMISSION_ACTIONS);
    throw new Error("Unable to get permission actions!")
  }

  return data.reduce((agg, { permission_id, actions }) => {
    agg[permission_id] = actions
    return agg
  }, {})
}
