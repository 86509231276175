import { MIN_LIGHT_ACTIONS_VERSION } from "@/store/cluster/composition";
import {
  ACTION_SILENCE_CREATE,
  ACTIONS_SILENCES_VIEW,
} from "@/store/cluster-auth/composition";

export const CONTACT_SLACK_URL =
  "https://robustacommunity.slack.com/archives/C02R0LVANKY";
export const UPGRADE_ACTION_URL =
  "https://docs.robusta.dev/master/setup-robusta/upgrade.html";
export const UPGRADE_MSG = `Upgrade to version ${MIN_LIGHT_ACTIONS_VERSION} to support this feature`;
export const CONTACT_US_MSG = "There is an issue with your robusta configuration, please contact support to solve.";
export const CLUSTER_NOT_CONNECTED_MSG = "This cluster is not connected. Contact support for assistance.";

export const SILENCE_ERROR_MSG = "Unable to read or modify silences on ";
export const SILENCE_CREATE_ERROR_MSG =
  "Unable to create silences for the following ";
export const PODS_UPGRADE_ERROR_MSG =
  "Data is missing due to an old Robusta version. Upgrade Robusta to fix.";
export const PODS_MISCONFIGURED_ERROR_MSG =
  "Data is missing due to a Robusta misconfiguration. Contact support to fix.";
export const PODS_NOT_CONNECTED_ERROR_MSG =
  "Data is missing due to the cluster not being connected. Contact support to fix.";

function sameArray(array1: string[], array2: string[]) {
  return (
    array1.length === array2.length &&
    array1.every((value) => array2.includes(value))
  );
}

export function GetErrorMessageTitle(
  actions: string[],
  clusterIssues: ClusterIssue[]
): string | undefined {
  if(clusterIssues.length == 0){
    return undefined
  }
  if (sameArray(actions, [ACTION_SILENCE_CREATE])) {
    return SILENCE_CREATE_ERROR_MSG;
  } else if (sameArray(actions, ACTIONS_SILENCES_VIEW)) {
    return SILENCE_ERROR_MSG + clusterIssues.length + " ";
  } else {
    if (
      clusterIssues.length > 1 ||
      clusterIssues[0].issueMessage == UPGRADE_MSG
    ) {
      return PODS_UPGRADE_ERROR_MSG;
    } else if (clusterIssues[0].issueMessage == CLUSTER_NOT_CONNECTED_MSG) {
      return PODS_NOT_CONNECTED_ERROR_MSG;
    } else {
      return PODS_MISCONFIGURED_ERROR_MSG;
    }
  }
}

export enum ClusterIssueActionType {
  CONTACT_US = "Contact",
  UPGRADE = "Upgrade",
}

export class ClusterIssueAction {
  actionType: ClusterIssueActionType;
  link?: string;
  actionButtonStr: string;
  constructor(actionType: ClusterIssueActionType, link?: string) {
    this.actionType = actionType;
    this.link = link;
    this.actionButtonStr = actionType;
  }
  public isContactAction(): boolean {
    return this.actionType == ClusterIssueActionType.CONTACT_US;
  }

  public static upgradeAction() {
    return new ClusterIssueAction(
      ClusterIssueActionType.UPGRADE,
      UPGRADE_ACTION_URL
    );
  }

  public static contactAction() {
    return new ClusterIssueAction(ClusterIssueActionType.CONTACT_US);
  }
}

export class ClusterIssue {
  clusterName: string;
  issueMessage: string;
  action: ClusterIssueAction;

  constructor(
    clusterName: string,
    issueMessage: string,
    action: ClusterIssueAction
  ) {
    this.clusterName = clusterName;
    this.issueMessage = issueMessage;
    this.action = action;
  }

  public static upgradeClusterIssue(clusterName: string) {
    return new ClusterIssue(
      clusterName,
      UPGRADE_MSG,
      ClusterIssueAction.upgradeAction()
    );
  }

  public static contactUsAboutClusterIssue(clusterName: string) {
    return new ClusterIssue(
      clusterName,
      CONTACT_US_MSG,
      ClusterIssueAction.contactAction()
    );
  }

  public static notConnectedClusterIssue(clusterName: string) {
    return new ClusterIssue(
      clusterName,
      CLUSTER_NOT_CONNECTED_MSG,
      ClusterIssueAction.contactAction()
    );
  }
}
