import supabase from "@/store/supabase-client"
import { ManualAction } from "@/manual-actions/types"
import SessionTokenService from "@/store/session-token/data"
import { runManualAction } from "@/manual-actions/actions-runner"
import { FailedToGetEvidenceFromResponseError } from "@/manual-actions/errors"
import { trackError, ErrorName } from '@/utils/track-error';

/**
 * A helper function that gets the relevant auth data to run an action, and
 * then runs the action.
 * It also parses the response from the runner's Result object to expose
 * a regular promise interface.
 */
export async function getAuthAndRunAction<Data>(fetcher: ManualAction<Data>): Promise<any> {
  const userId = <string>supabase.userId
  const accountId = <string>supabase.accountId

  let sessionToken
  try {
    sessionToken = await SessionTokenService.getOrCreate(userId, accountId)
  } catch(err) {
    throw new Error("Authentication failed")
  }

  const result = await runManualAction(fetcher, userId, accountId, sessionToken)

  if(result.error) {
    trackError(ErrorName.FAILED_TO_GET_AUTH_AND_RUN_ACTION);
    throw result.error
  }

  return result.data
}

/**
 * Extracts the evidence from the response.
 * Throws an indicative error if the evidence cannot be found at the expected place.
 */
export function getEvidenceFromResponse(response, findingIndex = 0, evidenceIndex = 0) {
  try {
    return response.data.findings[findingIndex].evidence[evidenceIndex]
  } catch {
    trackError(ErrorName.FAILED_TO_GET_EVIDENCE_FROM_RESPONSE);
    throw new FailedToGetEvidenceFromResponseError(findingIndex, evidenceIndex)
  }
}

export function getLastEvidenceFromResponse(response, findingIndex = 0) {
  try {
    const evidence = response.data.findings[findingIndex].evidence
    return evidence[evidence.length - 1]
  } catch {
    trackError(ErrorName.FAILED_TO_GET_LAST_EVIDENCE_FROM_RESPONSE);
    throw new Error("Failed to get last evidence from response")
  }
}
