import { NavigationGuardNext, Route } from "vue-router"
import { useGlobalModal } from "@/application/store/modals.store"

export default function globalModalGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  const { isActive, close } = useGlobalModal()

  if (isActive.value)
    close()

  next()
}