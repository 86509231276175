import { ActionType, ContactAction, LinkAction } from "@/notifications/actions/types"

export function contactUsAction(): ContactAction {
  return {
    type: ActionType.CONTACT,
	  text: 'Contact Support'
  }
}

export function upgradeClusterAction(): LinkAction {
  return {
    type: ActionType.LINK,
    url: "https://docs.robusta.dev/master/setup-robusta/upgrade.html",
    text: "Upgrade"
  }
}

export function seeDocsAction(url: string, text = 'See docs'): LinkAction {
  return {
    type: ActionType.LINK,
    url,
    text
  }
}
