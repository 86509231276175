import _ from 'lodash'
import { groupBy } from "@/utils/array";
import { formatUnitsToNumber } from "@/utils/units";
import { groupFiringAlerts, IFiringAlert } from "@/store/firing-alerts";
import semver from 'semver'
import { deepFreeze } from "@/utils/object"

export enum EStatuses {
	READY = 'ready',
	NOT_READY = 'not ready',
	ALERT = 'alert'
}

interface IContainer {
	name: string
	resources: {
		cpu: string
		memory: string
	}
}


export interface IService {
	name: string
	cluster: string
	namespace: string
	type: string
	ready_pods: number
	total_pods: number
	firingAlerts: IFiringAlert[] | string[]
	memoryRequests: number
	cpuRequests: number
	status: EStatuses

	config: {
		labels: Record<string, string>
		containers: IContainer[]
	} | null
}

export interface IStatusesGrouped {
	ready: number
	alert: number
	notReady: number
}

export interface IServiceGrouped {
	name: string
	cluster: number
	namespace: number
	type: string
	ready_pods: number
	total_pods: number
	firingAlerts: number
	status: IStatusesGrouped,
	details: IService[],
	last_changed?: string,
	total_mem?: number,
	total_cpu?: number,
	render_total?: number
}

export interface IServiceFilters {
	statuses: EStatuses[]
	types: string[],
	images: string[],
}

export type TSort = 'asc' | 'desc' | null
export enum ESortDirection {
	ASC = 'asc',
	DESC = 'desc'
}
export interface IServiceSorting {
	cluster: TSort
	namespace: TSort
	status: TSort
	lastChanged: TSort
	memoryReq: TSort
	cpuReq: TSort
}


export function mapGroupedServices (services, search, filters: IServiceFilters) {
	const groupedServices = groupBy(services, ['name', 'type'])

	return Object.entries(groupedServices)
		.reduce((acc, service) => {
			const [groupingKey, apps] = service
			const groupingKeyLowerCase = groupingKey.toLowerCase()

			const appInfo = service[1].reduce((acc, app) => {
				acc.ready_pods += app.ready_pods
				acc.total_pods += app.total_pods
				acc.clusters.push(app.cluster)
				acc.namespaces.push(app.namespace)
				acc.status.ready += app.firingAlerts.length ? 0 : Number(app.total_pods === app.ready_pods)
				acc.status.notReady += app.firingAlerts.length ? 0 : Number(app.total_pods !== app.ready_pods)
				acc.status.alert +=  Number(!!app.firingAlerts.length)
				acc.firingAlertsNum += app.firingAlerts.length
				acc.last_changed.push(app.update_time);
				return acc
			}, { ready_pods: 0,
				total_pods: 0,
				clusters: [],
				namespaces: [],
				status: { ready: 0, notReady: 0, alert: 0 },
				firingAlertsNum: 0,
				last_changed: [],
			})

			const details = service[1].reduce((innerAcc, app) => {
				const itemStatus = getStatus(app.firingAlerts?.length, app.total_pods, app.ready_pods)

				if (filterBy(
					[app.namespace, app.name, app.cluster],
					app.firingAlerts,
					search,
					itemStatus,
					app.type,
					app,
					filters
				)) {
					const requests = getMemoryAndCpuRequests(app.config?.containers ?? [])
					innerAcc[itemStatus].push({
						...app,
						status: itemStatus,
						memoryRequests: requests.memoryRequests,
						cpuRequests: requests.cpuRequests,
						firingAlerts: groupFiringAlerts(app.firingAlerts)
					})
				}

				return innerAcc
			}, { [EStatuses.ALERT]: [], [EStatuses.NOT_READY]: [], [EStatuses.READY]: [] }) // This separation by status saves us the need to sort with .sort() which leads to poor performance & sluggish screen

			const filteredDetails = [...details[EStatuses.ALERT], ...details[EStatuses.NOT_READY], ...details[EStatuses.READY]];

			let totalMemoryRequests = 0;
			let totalCpuRequests = 0;
			filteredDetails.forEach(item => {
				totalMemoryRequests += item.memoryRequests;
				totalCpuRequests += item.cpuRequests;
			});

			const group: IServiceGrouped = {
				name: apps[0].name,
				cluster: _.uniq(appInfo.clusters).length,
				namespace: _.uniq(appInfo.namespaces).length,
				type: apps[0].type,
				ready_pods: appInfo.ready_pods,
				total_pods: appInfo.total_pods,
				firingAlerts: appInfo.firingAlertsNum,
				status: {
					ready: appInfo.status.ready,
					notReady: appInfo.status.notReady,
					alert: appInfo.status.alert
				},
				last_changed: appInfo.last_changed.length ? appInfo.last_changed.sort().reverse()[0] : '',
				total_mem: totalMemoryRequests,
				total_cpu: totalCpuRequests,
				details: [...details[EStatuses.ALERT], ...details[EStatuses.NOT_READY], ...details[EStatuses.READY]],
				render_total: 10
			}

			const hasFilters = Object.values(filters).some((filter) => filter.length)
			if ((!hasFilters && (!search || groupingKeyLowerCase.includes(search))) || group.details.length) {
				acc.push(deepFreeze(group))
			}

			return acc
		}, [] as IServiceGrouped[])
}

export function mapUngroupedServices (services, search, filters: IServiceFilters) {
	return services.reduce((acc, el) => {
		const requests = getMemoryAndCpuRequests(el.config?.containers ?? [])
		const service: IService = {
			...el,
			status: getStatus(el?.firingAlerts?.length, el.total_pods, el.ready_pods),
			memoryRequests: requests.memoryRequests,
			cpuRequests: requests.cpuRequests,
			firingAlerts: groupFiringAlerts(el.firingAlerts)
		}

		if (filterBy(
			[service.namespace, service.name, service.cluster],
			service.firingAlerts,
			search,
			service.status,
			service.type,
			service,
			filters
		)) {
			acc.push(deepFreeze(service))
		}

		return acc
	}, [] as IService[])
}

function getMemoryAndCpuRequests (containers) {
	return containers.reduce((acc, item) => {
		acc.memoryRequests += formatUnitsToNumber(item.resources.requests.memory) || 0
		acc.cpuRequests += formatUnitsToNumber(item.resources.requests.cpu) || 0
		return acc
	}, { cpuRequests: 0, memoryRequests: 0 })
}

export function filterBy (arr: string[], firingAlerts, search, status, type, service, filters: IServiceFilters): boolean {
	const alerts = firingAlerts.map(({ name }) => (name))
	const hasFilters = Object.values(filters).some(filter => filter.length)

	const images = fetchImageNames(service);

	const filteredImages = filters.images.length ? images.filter((i) => filters.images.includes(i)).length : 1;

	return (!search && !hasFilters) || !![...arr, ...alerts, ...images].filter(str => {
		const isSearch = str.toLowerCase().includes(search)
		const isType = filters.types.length ? filters.types.includes(type) : true
		const isStatus = filters.statuses.length ? filters.statuses.includes(status) : true

		return isSearch && isType && isStatus && !!filteredImages
	}).length;
}

export function getStatus (alertsNumber: number, totalPods: number, readyPods: number): string {
	if (alertsNumber) {
		return EStatuses.ALERT
	} else if (totalPods === readyPods) {
		return EStatuses.READY
	} else {
		return EStatuses.NOT_READY
	}
}

export function getClusterNamespaceKey (cluster, namespace): string {
	return `${cluster}/${namespace}`
}

export function fetchImageNames(service): string[] {
	return (service?.config?.containers ?? []).map(c => c.image);
}

export function getImageShortName(name): string {
  if (!name) return ''
  let isVersion = false;
  if (name.includes('@sha256:')) {
    name = name.split('@sha256:');
    name = name[1];
    name = name.slice(0, 4) + '...' + name.slice(-4);
    isVersion = true;
  } else if (name.includes('/')) {
    name = name.split('/');
    name = name[name.length - 1];
    isVersion = true;
  }

  if (name.includes(':')) {
    isVersion = true;
    name = name.split(':')[1] ?? '';
  }
  if (semver.valid(name)) {
    isVersion = true;
    if (name[0] === 'v') {
      name = name.substr(1, name.length);
    }
  } else if (name[0] === 'v') {
    name = name.substr(1, name.length);
  }

  if (isVersion) {
    if (name.length == 64) {
      name = name.slice(0, 4) + '...' + name.slice(-4);
    } else if (name.length > 14) {
      name = name.split('-');
      if (name.length > 1) {
        name = name[0] + '-' + name[1].substr(0, 3) + '...';
      } else {
        name = name[0].substr(0, 14) + '...';
      }
    }
  } else {
    name = 'latest';
  }
  return name;
}

export const systemMessage = 'You are an AI observability/monitoring assistant for troubleshooting issues on Kubernetes.'
export const userMessage = `The following application is running on Kubernetes:

\`\`\`
{{{ yaml }}}
\`\`\`

The pod logs are:

\`\`\`
{{{ str_trunc_end podLogs 8000 }}}
\`\`\`

Are there any problems in the pod logs? If not, say "No errors were detected automatically." and finish. Ignore the rest of the instructions here in that case.

If yes, say that there were errors detected and list each family of log errors once, in approximately the following format (using markdown, updating "Multiple/single error(s)" according to the facts of each error):

------------
Multiple/single error(s) regarding <concise description, 1-7 words>. Example:
\`\`\`
<example log line with that error>
\`\`\`
------------

For example, one such section could be:

------------
Multiple errors regarding GET requests for favicon.ico :

\`\`\`
2023/06/29 19:34:12 [error] 25736#0: *6789 open() "/usr/share/nginx/html/example.com/favicon.ico" failed (2: No such file or directory), client: 192.168.0.101, server: localhost, request: "GET /favicon.ico HTTP/1.1", host: "www.example.com", referrer: "http://www.example.com/example_page.html"
\`\`\``

export const DYNAMIC_LOG_PLACEHOLDER = 'DYNAMIC_LOG_PLACEHOLDER_HERE'
export const userMessageForAiSummary = `Pod Logs:
'''
${DYNAMIC_LOG_PLACEHOLDER}
'''
----------------

Use the provided Kuberenetes application pod logs.

First, output a section with the markdown header "# Summary" and summarize the log.
Second, output a section with the markdown header "# Issues" and in this section summarize any crashes or exceptions in the pod logs. Cite exact snippets from the logs in markdown code block to explain. Why does the error occur?`

export const userMessageForAnalyzeIssues = `The Kubernetes app is broken.

Here is some information for reference:
The yaml:
'''
{{{ yaml }}}
'''
The Kubernetes events of the pods:
'''
{{{ to_json podEvents }}}
'''
The pod's yaml:
'''
{{{ podYaml }}}
'''
The pod's logs:
'''
{{{ logs_dynamic podLogs }}}
'''

Based on of the above information, identify the main problem that prevents this app from running correctly. Provide quotes from the data to support your claim (write them as Markdown code-blocks). Can the problems be related?`
