<template>
  <div>
    <div
      v-if="isLoading"
      class="text-3xl flex h-lvh items-center mx-auto justify-center w-fit gap-3"
    >
      <span>Loading</span>
      <span>
        <i class="fas fa-sync fa-spin"></i>
      </span>
    </div>

    <router-view v-else />
  </div>
</template>

<script setup lang="ts">
import supabase from '@/store/supabase-client';
import { useRoute } from 'vue-router/composables';
import { computed } from 'vue';

const route = useRoute();

// We check route.name because when we might have a scenario where supabase isn't loading
// anymore, but we still don't have a route because not all guards completed.
const isLoading = computed(() => supabase.loading || !route.name);
</script>
