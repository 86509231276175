import Vue from 'vue'
import '@/plugins'
import '@/monitoring'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from '@/router'
import App from '@/App.vue'
import '@/assets/style.scss'

Vue.config.productionTip = false

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = new Vue({
  router,
  pinia,
  render: (h) => h(App)
}).$mount('#app')
