import { Route } from "vue-router"
import { deserialize } from "@/composables/use-sync-query-params"
import { RouteName } from "@/router/router-builder"

export function queryParamToProp(
  paramName: string,
  route: Route,
  decodeFn?: (encoded: any) => any
): Record<string, any> {
  try {
    const value = deserialize(route.query[paramName])
    return { [paramName]: decodeFn ? decodeFn(value) : value }
  } catch (exc) {
    console.error(`Failed to parse query param ${paramName}!`)
    return {}
  }
}

export function isAuthenticationRoute(routeName: string) {
  return routeName === RouteName.Login || routeName === RouteName.SignUp
}