import { NavigationGuardNext, Route } from "vue-router"

export default function accountQueryParamCleanupGuard(to: Route, _from: Route, next: NavigationGuardNext) {
  if (
    !to.query.accountName &&
    !to.query.account_name &&
    !to.query.accountId &&
    !to.query.account_id &&
    !to.query.account
  ) {
    return next()
  }

  const newQuery = { ...to.query }
  delete newQuery.accountName
  delete newQuery.account_name
  delete newQuery.accountId
  delete newQuery.account_id
  delete newQuery.account
  next({
    name: <string>to.name,
    params: to.params,
    query: newQuery,
    hash: to.hash,
  })
}