import supabase from "@/store/supabase-client"
import { definitions } from "@/store/supabase-autogenerated"
import semver from 'semver'
import { getCompatibilityFields } from "@/store/cluster/composition";
import { filterDeadClusters } from "@/store/dead-clusters";
import { trackError, ErrorName } from '@/utils/track-error';

export type ClustersStatus = definitions["ClustersStatus"]

export type ClustersStatusesQueryOptions = {
  cluster: string
  filterDead: boolean
  limit: number
}
const DEFAULT_CLUSTERS_STATUSES_QUERY_OPTIONS = {
  filterDead: true
}
export async function fetchClustersStatuses(
  accountId: string,
  options?: Partial<ClustersStatusesQueryOptions>
): Promise<readonly ClustersStatus[]> {
  const config = { ...DEFAULT_CLUSTERS_STATUSES_QUERY_OPTIONS, ...options }

	const requestMock = supabase.client
		.from<ClustersStatus>('ClustersStatus')
		.select('*')
		.eq('account_id', accountId)

  const request = config.filterDead
    ? filterDeadClusters(requestMock, 'cluster_id')
    : requestMock

  if(config.cluster) {
    request.eq('cluster_id', config.cluster)
  }

  if(config.limit) {
    request.limit(config.limit)
  }

  const { error, data } = await request

  if(error) {
    trackError(ErrorName.FAILED_TO_FETCH_CLUSTERS_STATUSES);
    throw Error(`Error getting clusters`)
  }

  return data!.map(
    cs => ({
      ...cs,
      version: semver.parse(cs.version)?.version ?? '0.0.0',
	    compatibility: getCompatibilityFields(cs)
    })
  )
}

export async function deleteCluster(accountId: string, cluster: string) {
  const response = await supabase.client
    .rpc('delete_cluster', {
      '_account_id': accountId,
      '_cluster': cluster
    })

  if(response.error) {
    trackError(ErrorName.FAILED_TO_DELETE_CLUSTER);
    throw new Error(`Error deleting cluster`)
  }

  return response.data as unknown as boolean
}
