import supabase from '@/store/supabase-client'
import { LimitedAccountData } from './types'
import { trackError, ErrorName } from '@/utils/track-error';

export async function fetchSingleUserAccount(accountId: string): Promise<LimitedAccountData | undefined> {
  const { data, error } = await supabase.client
    .from('SingleUserAccount')
    .select('allowed_user_id,admin_email')
    .eq('account_id', accountId)
    .lte('enforcement_start', new Date().toISOString())
  if(error) {
    trackError(ErrorName.FAILED_TO_FETCH_SINGLE_USER_ACCOUNT);
    throw new Error('Error getting account user limitations')
  }
  if(!data.length) {
    return undefined
  }
  return {
    allowedUserId: data[0].allowed_user_id,
    adminEmail: data[0].admin_email
  }
}
