import supabase from "@/store/supabase-client"
import { UserAuthorizedActions } from "@/store/rbac/user-permissions/types"
import { trackError, ErrorName } from '@/utils/track-error';

export async function getUserAuthorizedActions(accountId: string): Promise<UserAuthorizedActions> {
  const { error, data } = await supabase.client.rpc('get_user_permissions', {
    _account_id: accountId
  })

  if(error || !data) {
    trackError(ErrorName.FAILED_TO_GET_USER_AUTHORIZED_ACTIONS);
    throw new Error('Error getting user authorized actions')
  }

  return data as unknown as UserAuthorizedActions
}
