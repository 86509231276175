import { cloneDeep } from "lodash";

export function groupBy<T extends any[] | readonly any[]>(arr: T, fields: string | string[]): Record<string, T> {
  fields = typeof(fields) === "string" ? [fields] : fields

  return arr.reduce(function(groups, item) {
    const key = (<string[]>fields).reduce((k, f) => k ? `${k}/${item[f]}` : item[f], "")
    // @ts-ignore - if it's a subclass of Array, use it instead
    groups[key] = groups[key] || new arr.constructor()
    groups[key].push(item)
    return groups
  }, {})
}

export function isNotEmptyArray(arr: any[] | readonly any[] | undefined): boolean {
  return Array.isArray(arr) && arr.length > 0
}

/**
 * Returns a combination of all possible permutations of given arrays.
 * For example, for the following arrays:
 *    [1, 2]
 *    ["a", "b"]
 *    [{ foo: 1 }, { bar: 1 }]
 * You will get 8 different permutations:
 *    [
 *      [1, "a", { foo: 1 }],
 *      [1, "a", { bar: 1 }],
 *      [1, "b", { foo: 1 }],
 *      [1, "b", { bar: 1 }],
 *      [2, "a", { foo: 1 }],
 *      [2, "a", { bar: 1 }],
 *      [2, "b", { foo: 1 }],
 *      [2, "b", { bar: 1 }],
 */
export function permuteArrays(first: any[], next: any[], ...rest: Array<any[]>): any[] {
  if (rest.length) { // @ts-ignore
    next = permuteArrays(next, ...rest)
  }
  return first.flatMap(a => next.map(b => [a, b].flat()))
}

/**
 * Returns an Array of unique values.
 * @param arr
 * @param fnOrKey:
 *    When string, the unique values will be item[fnOrKey]
 *    When function, the unique values will be fnOrKey(item)
 */
export function unique(arr: Array<any>, fnOrKey: string | ((any) => any)): any[] {
  if(!Array.isArray(arr))
    throw new Error("unique() is Expecting Array")

  const uniqueValues = new Set()
  for (const obj of arr) {
    let value
    if(typeof(fnOrKey) === "function")
      value = fnOrKey(obj)
    else
      value = obj[fnOrKey]
    uniqueValues.add(value)
  }

  return [ ...uniqueValues ]
}

/**
 * Takes an array of objects and returns an object with key: val per item in the original array.
 * For example:
 *    For the array [ { name: "James", age: 15 }, { name: "Susan", age: 20 } ] Result would be
 *    {
 *      James: 15,
 *      Susan: 20
 *    }
 * @param arr
 * @param getKey: A function to extract the item's key
 * @param getVal: A function to extract the item's value
 */
export function flattenArrayOfObjects(arr: Record<string, any>[], getKey?: (item) => string, getVal?: (item) => any): Record<string, any> {
  if(!Array.isArray(arr) || !arr.length) {
    return {}
  }

  getKey = getKey || ((i) => i.name)
  getVal = getVal || ((i) => i.value)

  return arr.reduce((flat, item) => {
    flat[getKey!(item)] = getVal!(item)
    return flat
  }, {})
}

export function shuffleArray<T>(array: T[]) {
  const arrayCopy = [...array];
  for(let i = 0; i < arrayCopy.length; i++) {
    const rand = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[rand]] = [arrayCopy[rand], arrayCopy[i]];
  }
  return arrayCopy;
}

/**
 * Extending an array in JS: https://stackoverflow.com/questions/11337849/ways-to-extend-array-object-in-javascript
 */
export class CustomArray<T> extends Array<T> {
  constructor(...args: number[] | T[]) {
    if(!args)
      super()
    else if(args.length === 1 && typeof(args[0]) === "number")
      super(args[0])
    else {
      super()
      for (const s of <T[]>args) this.push(s)
    }
  }
}

/**
 * Take an array of typed objects and modifies an array with the key
 * For example:
 *    For the array [ 10, 0, 0, 100 ] Output is [ 10, 55, 77.5, 100 ]
 *    For the array [ 0, 0, 0, 100 ] Output is [ 100, 100, 100, 100 ]
 *    For the array [ 100, 0, 0, 0 ] Output is [ 100, 100, 100, 100 ]
 * @param items is a array of typed objects
 * @param field is a key parameter to search in an object
 */
export function extrapolate<T>(items: T[], field: string) {
  for(let i = 0; i < items.length; i++) {
    if(items[i][field] === 0) {
      let nearestLeft: undefined | number

      if(i !== 0) {
        let left = i - 1
        while(left >= 0) {
          if(items[left][field] !== 0) {
            nearestLeft = items[left][field]
            break
          } else {
            left--
          }
        }
      }

      let nearestRight: undefined | number
      if(i !== items.length - 1) {
        let right = i + 1
        while(right <= items.length - 1) {
          if(items[right][field] !== 0) {
            nearestRight = items[right][field]
            break
          } else {
            right++
          }
        }
      }

      if(nearestLeft && nearestRight) {
        items[i][field] = (nearestLeft + nearestRight) / 2
      } else if(nearestLeft && !nearestRight) {
        items[i][field] = nearestLeft
      } else if(!nearestLeft && nearestRight) {
        items[i][field] = nearestRight
      }
    }
  }
}
