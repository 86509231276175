import { definitions } from "@/store/supabase-autogenerated"

export interface EnvVar {
  name: string
  value: any
}

export interface Resources {
  limits: { memory: string, cpu: string }
  requests: { memory: string, cpu: string }
}

export interface Container {
  key: string, // Added on the client side
  image: string
  imageShortName?: string // Added on the client  side
  name: string
  resources: Resources
  env: EnvVar[]
  ports: string[]
}

export const ACTION_RESTART_ROLLOUT = 'rollout_restart'

export type Service = definitions["Services"] & {
  groupingKey: string // We group services by this key
  getContainerByName: (name: string) => Container | null
  recentCrashes?: any[] // We need this for service details to compute the status - but a better way is to use serviceV2
}
