import {
  BarController,
  BarElement,
  Chart,
  LineController,
  ScatterController,
  PointElement,
  LineElement,
  Filler,
  LinearScale,
  CategoryScale,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'

Chart.defaults.font.family = 'Roboto'
Chart.register(
  BarController,
  BarElement,
  LineController,
  ScatterController,
  PointElement,
  LineElement,
  Filler,
  LinearScale,
  CategoryScale,
  TimeScale,
  Title,
  Tooltip,
  zoomPlugin,
  annotationPlugin
)
