import { ref } from "vue"
import { UserType } from '@/store/account/types'
import { Permission, PermissionActions } from "@/store/rbac/permissions-metadata/types"
import { AuthGranularity, UserAuthorizedActions } from "@/store/rbac/user-permissions/types"

// A map between permissions and their required actions.
export const permissionActions = ref<PermissionActions>({} as PermissionActions)

// All the actions that the user is authorized to run.
export const userAuthorizedActions = ref<UserAuthorizedActions>({})

export const userType = ref(UserType.None)

/**
 * Returns a boolean indicating if a permission is authorized for the active user.
 * @param permission - The permission to check.
 * @param granularity - If granularity is provided, the permission will be checked against
 *    a specific cluster and/or namespace.
 */
export function isAuthorized(permission: Permission, granularity?: AuthGranularity, ignoreNamespace?: boolean): boolean {
  if(!(window as any).ENV_RBAC_ENABLED)
    return true
  if(userType.value !== UserType.RBAC)
    return true
  const requiredActions = _getPermissionActions(permission)
  return requiredActions.every(action => _isActionAuthorized(action, granularity, ignoreNamespace))
}

function _getPermissionActions(permission: Permission) {
  return permissionActions.value[permission] || []
}

function _isActionAuthorized(action: string, granularity?: AuthGranularity, ignoreNamespace?: boolean): boolean {
  const isAuthorizedAtAll = action in userAuthorizedActions.value

  if(!granularity)
    return isAuthorizedAtAll
  if(!isAuthorizedAtAll)
    return false

  const { cluster, namespace } = granularity
  const authorizedScope = userAuthorizedActions.value[action]

  if(!(cluster in authorizedScope))
    return false
  if(authorizedScope[cluster][0] === "*")
    return true
  if(ignoreNamespace) {
    return true
  }
  return !!namespace && authorizedScope[cluster].includes(namespace)
}

export function getNotAuthorizedMessage(action: string) {
  return `You can't access ${action} action`
}

/**
 * Returns all the clusters that are authorized for a specific action.
 */
function getAuthorizedClustersForAction(action: string): string[] {
  return Object.keys(userAuthorizedActions.value[action])
}

/**
 * Returns all the clusters that are authorized for a specific permission.
 * A cluster is considered authorized, only if it's authorized for all the actions associated with the permission.
 */
export function getAuthorizedClustersForPermission(permission: Permission) {
  const actions = permissionActions.value[permission]
  const counter: Record<string, number> = {}

  // For each cluster, count for how many actions it's authorized
  actions.forEach(_action => {
    const clusters = getAuthorizedClustersForAction(_action)
    clusters.forEach(_cluster => counter[_cluster] = (counter[_cluster] ?? 0) + 1)
  })

  // Return only the clusters that are authorized for all actions
  return Object.keys(counter).reduce((clusters, _cluster) => {
    if(counter[_cluster] === actions.length)
      clusters.push(_cluster)
    return clusters
  }, [] as string[])
}