export type ServiceKey = { namespace: string, type: string, name: string }

export function parseServiceKey(key: string | undefined): ServiceKey {
  const [namespace, type, name] = key ? key.split("/") : ["", "" , ""]

  return {
    namespace,
    type,
    name
  }
}

/**
 * Returns a key string in the following format: <SERVICE_TYPE>/<SERVICE_NAME>.
 */
export function getServiceGroupingKey(serviceType: string | undefined, serviceName: string | undefined): string {
  let key = ""
  if(serviceType) key = serviceType
  if(serviceName) key = key ? `${key}/${serviceName}` : serviceName
  return key
}
