import { post } from "@/api/request";

export const RELAY_HOST = (window as any).ENV_RELAY_HOST as any;
const endpoint_add_user = `${RELAY_HOST}/account/join`;
const endpoint_invite_email = `${RELAY_HOST}/account/invite`;
const endpoint_delete_user = `${RELAY_HOST}/account/remove`;
const endpoint_change_user = `${RELAY_HOST}/users/update`;

export function postAddUserToAccount(requestID: string, userId: string): Promise<void>{
   const data = JSON.stringify({ "request_id": requestID, "user_id": userId});
   return post<void>(endpoint_add_user, data);
}

export function postInviteUserEmailToAccount(accountId: string, emailInvitee: string, emailInvitor: string, accountName: string, sessionToken: string): Promise<void>{
   const data = JSON.stringify({"account_id": accountId, "email_invitee": emailInvitee, "email_invitor": emailInvitor, "account_name": accountName, "token": sessionToken});
   return post<void>(endpoint_invite_email, data);
}

export function postChangeAccountUserRole(tokenId: string, userIdToChange: string, accountId: string, role: string): Promise<void>{
  // role is either "ADMIN" or "USER"
  const data = JSON.stringify({ "session_token": tokenId, "user_id": userIdToChange, "account_id": accountId, "role": role});
  return post<void>(endpoint_change_user, data);
}

export function postDeleteUserFromAccount(tokenId: string, userID: string, userIdToRemove: string, accountId: string): Promise<void>{
   const data = JSON.stringify({ "session_token": tokenId, "user_id": userIdToRemove, "account_id": accountId});
   return post<void>(endpoint_delete_user, data);
}
